<template>
  <v-dialog persistent v-model="dialog" max-width="500">
    <v-card :loading="bLoading" v-if="dialog">
      <v-card-title>
        <span class="text-h5">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <form-field-text
                v-model="sRut"
                label="RUT"
                mask="##-######-###-#"
                :hint="$t('new.company.rut.info')"
                persistent-hint
              />
            </v-col>
            <v-col cols="12" v-if="obFirmPrevData.name">
              <company-preview :company="{ firm: obFirmPrevData }" hide-title>
                <v-chip label outlined small>
                  {{ obFirmPrevData.doc_id }}
                </v-chip>
              </company-preview>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialog = false">{{ $t("cancel") }}</v-btn>
        <v-btn text color="primary" @click="getCompanyData" v-text="btnLabel" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {
  Vue,
  Component,
  VModel,
  PropSync,
  Watch,
  Prop,
} from "vue-property-decorator";
import Ucfe from "@/services/Ucfe";
import type { Company, FirmData } from "@planetadeleste/vue-mc-gw";
import { FlashModule } from "@/store/flash";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import { toNumeric } from "@/components/functions/toNumeric";
import { toCode } from "@/plugins/helpers";

@Component({
  components: { CompanyPreview },
})
export default class RutFormDialog extends Vue {
  @VModel({ type: Object }) obCompany!: Company;
  @PropSync("open", { type: Boolean, default: false }) dialog!: boolean;
  @Prop({ type: String, default: "new.company" }) readonly title!: string;

  sRut = "";
  bLoading = false;
  obFirmPrevData: FirmData | Record<string, any> = {};

  @Watch("dialog")
  onClose(bDialog: boolean) {
    if (bDialog) {
      this.sRut = "";
      this.obFirmPrevData = {};
    }
  }

  async getCompanyData() {
    if (this.$_.isEmpty(this.sRut)) {
      this.$emit("update:open", false);
      return;
    }

    const sRutClean = toNumeric(this.sRut);

    if (sRutClean == this.obFirmPrevData.doc_id) {
      this.obCompany.set("firm", this.obFirmPrevData);

      this.$_.delay(() => {
        this.$_.set(this.obCompany, "firm.doc_id", sRutClean);
        this.$emit("update:open", false);
      }, 500);

      return;
    }

    this.bLoading = true;
    const obResponse = await Ucfe.getFirmData(this.sRut, true);
    const obData = obResponse.data;

    if (this.$_.isEmpty(obData)) {
      FlashModule.info(this.$t("dgi.rut.emptyresponse"));
      this.bLoading = false;
      return;
    }

    this.obFirmPrevData = this.$_.clone(obData);
    this.obFirmPrevData.code = toCode(this.obFirmPrevData.name);

    this.bLoading = false;
  }

  get btnLabel() {
    return this.$t(this.obFirmPrevData.name ? "use.company.data" : "continue");
  }
}
</script>
