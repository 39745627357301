<template>
  <sheet-drawer v-model="drawer" @close="cancel">
    <v-form class="fill-height" @submit.prevent v-if="obModel && drawer">
      <customer-form
        v-model="obModel"
        @cancel="cancel"
        @save="save"
        :loading="isLoading"
        :dirty="isDirty"
      />
    </v-form>
  </sheet-drawer>
</template>

<script lang="ts">
import { Mixins, Component, PropSync, Provide } from "vue-property-decorator";
import CustomersMixin from "@/modules/customers/mixins/CustomersMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import CustomerForm from "@/modules/customers/components/CustomerForm.vue";
import { ref } from "@/services/Utils";

@Component({
  components: {
    SheetDrawer,
    CustomerForm,
  },
})
export default class CustomersForm extends Mixins(CustomersMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  @Provide() public isCustomerRef = ref<boolean>(true);

  tab = 0;
  tabItems: string[] = [
    "form-tab-settings",
    "form-tab-location",
    "form-tab-dgi",
  ];
  sTabPayment = "form-tab-payment";

  onMounted() {
    if (!this.drawer || !this.obModel.id) {
      if (this.tabItems.includes(this.sTabPayment)) {
        this.tabItems.splice(1, 1);
      }

      return;
    }

    if (!this.tabItems.includes(this.sTabPayment)) {
      this.tabItems.splice(1, 0, this.sTabPayment);
    }

    this.loadRelations();
  }

  async loadRelations() {
    const obCurrenciesResponse = await this.obModel.getCurrencies();
    if (obCurrenciesResponse) {
      this.obModel.set("currencies", obCurrenciesResponse.getData().data);
    }

    const obPaymentTermsResponse = await this.obModel.getPaymentTerms();
    if (obPaymentTermsResponse) {
      this.obModel.set("paymentterms", obPaymentTermsResponse.getData().data);
    }
  }
}
</script>
