<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import CustomersFilters from "@/modules/customers/components/CustomersFilters.vue";
import FiltersRowMixin from "@/mixins/FiltersRowMixin";
import InputFilters from "@/components/form/InputFilters.vue";
import CategoriesSelect from "@/modules/categories/components/CategoriesSelect.vue";
import SelectDocType from "@/components/form/fields/SelectDocType.vue";
import CustomerTypeSelect from "@/modules/customers/components/CustomerTypeSelect.vue";

@Component({
  components: {
    CustomerTypeSelect,
    SelectDocType,
    CategoriesSelect,
    InputFilters,
  },
})
export default class CustomerFiltersRow extends Mixins(
  FiltersRowMixin,
  CustomersFilters
) {}
</script>

<template>
  <input-filters
    ref="inputFilters"
    v-model="obFilterData"
    :model-name="sModelFilterKey"
    :headers="headerValueList"
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <template #name>
      <form-field-text
        v-model="obFilterData.name"
        @input:debounce="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #type>
      <customer-type-select
        v-model="obFilterData.customer_type"
        @change="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #email>
      <form-field-text
        v-model="obFilterData.email"
        @input:debounce="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #phone>
      <form-field-text
        v-model="obFilterData.phone"
        @input:debounce="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #doc_id>
      <form-field-text
        v-model="obFilterData.doc_id"
        @input:debounce="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #doc_type>
      <select-doc-type
        v-model="obFilterData.doc_type"
        @change="onUpdateFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #actions>
      <div class="text-right">
        <v-btn text color="primary" @click="onResetFilters">
          <v-icon>mdi-find-replace</v-icon>
        </v-btn>
      </div>
    </template>
  </input-filters>
</template>
